import Carousel from '../components/Carousel'
import Column from '../components/Column'
import Footer from '../components/Footer'
import Header from '../components/Header'

import img1 from '../assets/img1.jpg'
import img2 from '../assets/img2.jpg'
import img3 from '../assets/img3.jpg'
import img4 from '../assets/img4.jpg'
import img5 from '../assets/img5.jpg'

export default function Home() {
  return (
    <Column>
      <Header />
      <Carousel title={'Sac sur-mesure pur cuir'} nbImage={2}>
        <img src={img1} alt="img1" />
        <img src={img2} alt="img1" />
      </Carousel>
      <Carousel title={'Nous vous livrons tout type de cuir'} nbImage={3}>
        <img src={img3} alt="img1" />
        <img src={img4} alt="img1" />
        <img src={img5} alt="img1" />
      </Carousel>
      <Footer />
    </Column>
  )
}
