import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  gap: 20px;
`

export default function Column(props) {
  return <Container>{props.children}</Container>
}
