import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, Routes } from 'react-router-dom'
import { Route } from 'react-router'

import reportWebVitals from './utils/reportWebVitals'
import GlobalSyles from './styles/GlobalStyles'
import Home from './pages/Home'
import { Helmet } from 'react-helmet'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Router>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <GlobalSyles />
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
