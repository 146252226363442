import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  text-align: center;
  gap: 15px;
  color: grey;
`
const Contact = styled.div`
  margin-right: 13%;
  text-align: end;
`

export default function Footer() {
  return (
    <Container>
      <Contact>
        CONTACTEZ NOUS <br />
        (0033)06 51 57 57 50 <br />
        nzoda@hotmail.com
      </Contact>
      Adresse et informations sur la société numéro de siret etc.
    </Container>
  )
}
