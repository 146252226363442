import Column from './Column'

import styled from 'styled-components'

const Title = styled.div`
  font-family: 'Reenie Beanie', cursive;
  font-size: 26px;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  & img {
    width: ${({ nbImage }) => {
      return `${100 / nbImage - 10}%`
    }};
    height: auto;
    object-fit: contain;
  }
`

export default function Carousel({ title, nbImage, children }) {
  return (
    <Column>
      <Title>{title}</Title>
      <Row nbImage={nbImage}>{children}</Row>
    </Column>
  )
}
