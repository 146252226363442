import Column from './Column'
import logo from '../assets/logo.png'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
`
const Image = styled.img`
  width: 50%;
`

const NavBox = styled.div`
  display: flex;
  padding: 10px 0;
  gap: 10px;
`

const StyledLink = styled(NavLink)`
  color: gray;
  text-decoration: none;
`

const routes = ['sacs', 'vêtements', 'accessoires', 'boutique']

// const Route = styled(Link)``

export default function Header() {
  return (
    <Column>
      <ImageBox>
        <Image src={logo} alt="logo freedom" />
      </ImageBox>
      <NavBox>
        {routes.map((label, index) => (
          <StyledLink key={index}>{label}</StyledLink>
        ))}
      </NavBox>
    </Column>
  )
}
